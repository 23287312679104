import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useParams } from "react-router";
import { get, post } from "../../requests";
import moment from "moment";

import logo from '../../assets/Logo.png';
import './index.css';

const TRANSACTIONS = {
	TYPE: {
		SUBSCRIBE_FUND: 'subscribe fund',
		RESCUE_FUND: "rescue fund",
		BUY_BOND: 'buy bond',
		SELL_BOND: 'sell bond',
	},
}

function Transaction() {
	const [user, setUser] = useState();
	const [transactionType, setTransactionType] = useState();
	const [unitType, setUnitType] = useState("Monto");
	const [account, setAccount] = useState();	
	const [bond, setBond] = useState();
	const [bondPrice, setBondPrice] = useState();
	const [fund, setFund] = useState();	
	const [currency, setCurrency] = useState();
	const [amount, setAmount] = useState();
	const [transferToAccount, setTransferToAccount] = useState()
	const [createdAt, setCreatedAt] = useState()
	const [term, setTerm] = useState()
	const [confirmed, setConfirmed] = useState()
	const [confirmationAttempts, setConfirmationAttempts] = useState()
	const [pin, setPin] = useState();

	const [loading, setLoading] = useState(true);
	const [invalidCode, setInvalidCode] = useState(false)
	const [noAttempts, setNoAttempts] = useState(false)
	const [outOfTime, setOutOfTime] = useState(false)
	const [otherError, setOtherError] = useState(false)

	const [tokenExpired, setTokenExpired] = useState();

	const { transactionToken } = useParams();

	useEffect(() => {
		get({
			apiRoute: '/transactions',
			headers: {"Authorization": `Bearer ${transactionToken}`},
		})
		.then(function (response) {
			const {
				user,
				transactionType,
				account,
				bond,
				bondPrice,
				fund,
				currency,
				amount,
				transferToAccount,
				createdAt,
				term,
				confirmed,
				confirmationAttempts,
			} = response.data.data;

			switch (transactionType) {
				case TRANSACTIONS.TYPE.BUY_BOND:
					setTransactionType("Compra de Bono")
					break;
				case TRANSACTIONS.TYPE.RESCUE_FUND:
					setTransactionType("Rescate de Fondo")
					break;
				case TRANSACTIONS.TYPE.SELL_BOND:
					setTransactionType("Venta de Bono")
					setUnitType("Unidades")
					break;
				case TRANSACTIONS.TYPE.SUBSCRIBE_FUND:
					setTransactionType("Subscripcion de Fondo")
					break;
				default:
					setTransactionType("Tipo de transaccion desconosido")
					break;
				}
			setUser(user)
			setAccount(account)
			setBond(bond)
			setBondPrice(bondPrice)
			setFund(fund)
			setCurrency(currency)
			setAmount(amount);
			setTransferToAccount(transferToAccount)
			setCreatedAt(createdAt)
			setTerm(term)
			setConfirmed(confirmed)
			setConfirmationAttempts(confirmationAttempts)
			setNoAttempts(confirmationAttempts === 0)
			setLoading(false);
		})
		.catch((error) => {
			setTokenExpired(error.response.data.errorMessage === "TOKEN.EXPIRED");
			setOutOfTime(error.response.data.errorMessage === "OUT_OF_TIME");
			setLoading(false);
			console.log("Un error ha ocurrido: ", error);
		})
	}, [transactionToken, invalidCode])

	const submitTransaction = () => {
		setLoading(true);
		post({ 
			body: {confirmationCode: pin}, 
			apiRoute: '/transactions/confirm', 
			headers: {"Authorization": `Bearer ${transactionToken}`}})
		.then(res => {
			setConfirmed(true)
			setLoading(false);
		})
		.catch(error => {
			console.log(error.response)
			switch (error.response.data.errorMessage) {
				case "NO_ATTEMPTS":
					setNoAttempts(true);
					break;
				case "INVALID.CONFIRMATION_CODE": 
					setInvalidCode(!invalidCode);
					break;
				case "TOKEN.EXPIRED":
					setTokenExpired(true);
					break;
				case "OUT_OF_TIME":
					setOutOfTime(true);
				break;
				default:
					setOtherError(true)
					break;
			}
			setLoading(false);
		})
	}

	if (loading)
		return (
			<div className="loader-container">
				<div className="loader-box">
					<Loader
						type="Oval"
						color="#004781"
						height={100}
						width={100}
						visible={loading}
					/>
				</div>
			</div>
		)

	return (
    <div className="body-background">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
			<body >
				{tokenExpired ?
					<div className="App-body">
						<div className="title-box">
							<span className="token-expired-text">El token ha exipirado. Volvé a hacer otra petición con el bot de Whatsapp</span>
						</div>
					</div>
				:
					outOfTime ?
						<div className="App-body">
							<div className="title-box">
								<span className="token-expired-text">Te informamos que el horario para ingresar órdenes es entre las 10 y las 15.30 hs. <br/>
									Te esperamos dentro del rango horario indicado. ¡Gracias!</span>
							</div>
						</div>
					:

						confirmed ? 
							<div className="App-body">
								<div className="title-box">
									<span className="success-text">Se confirmó tu transacción con éxito</span>
								</div>
							</div>
						:	

						noAttempts ? 
							<div className="App-body">
								<div className="title-box">
									<span className="big-error-text">No tenés más intentos. Volvé a hacer otra petición con el bot de Whatsapp</span>
								</div>
							</div>
						:
						
							<div className="App-body">
								<div className="title-box">
									<span className="title">Información de la Transacción</span>
								</div>
								<div className="text-box">
									<span>Cliente: </span>
									<span>{user.firstName} {user.lastName}</span>
								</div>
								<div className="text-box">
									<span>Tipo de transacción: </span>
									<span>{transactionType}</span>
								</div>
								<div className="text-box">
									<span>Cuenta: </span>
									<span>{account.name}</span>
								</div>
								{bond.name &&
									<div className="text-box">
										<span>Bono: </span>
										<span>{bond.name}</span>
									</div>
								}
								{fund.name &&
									<div className="text-box">
										<span>Fondo: </span>
										<span>{fund.name}</span>
									</div>
								}
								{bondPrice.price &&
									<div className="text-box">
										<span>Precio: </span>
										<span>{bondPrice.price.charAt(0).toUpperCase() + bondPrice.price.slice(1)}</span>
									</div>
								}
								{bondPrice.minPrice && bondPrice.maxPrice &&
									<div className="text-box">
										<span>Rango de precios: </span>
										<span>Desde {bondPrice.minPrice} hasta {bondPrice.maxPrice}</span>
									</div>
								}
								<div className="text-box">
									<span>Moneda: </span>
									<span>{currency}</span>
								</div>
								<div className="text-box">
									<span>{unitType}: </span>
									<span>{amount}</span>
								</div>
								{term &&
									<div className="text-box">
										<span>Plazo: </span>
										<span>{term}</span>
									</div>
								}
								{transferToAccount !== undefined &&
									<div className="text-box">
										<span>Luego de la transacción: </span>
										{transferToAccount ? 
											<span className="transfer-text">Se transfiere a su cuenta bancaria</span> :
											<span className="transfer-text">Se mantiene en su cuenta comitente</span>
										}
									</div>
								}
								<div className="text-box">
									<span>Fecha: </span>
									<span>{moment(createdAt).format("DD/MM/YYYY h:mm:ss")}</span>
								</div>
								<div className="button-box">
									<span>Ingresá tu código de seguridad para confirmar la transacción</span>
									<br/>
									<input
										placeholder="Código de seguridad" 
										className="input" 
										value={pin} 
										disabled={noAttempts} 
										onChange={(event) => {setPin(event.target.value)}}
									/>
									<button
										className={!pin || noAttempts ? "disabled-button" : "button"}
										onClick={submitTransaction}
										disabled={!pin || noAttempts}
									>
										Confirmar
									</button>
								</div>
								{confirmationAttempts !== undefined && confirmationAttempts < 3 && !noAttempts &&
									<div className="result-box">
										{
											<>
												<p className="error-text">El código de seguridad ingresado es incorrecto<br/><br/>
												{confirmationAttempts === 1 ? "Te queda 1 intento" : `Te quedan ${confirmationAttempts} intentos`}</p>
											</>
										}
									</div>
								}
								{otherError &&
									<div className="result-box">
										{
											<>
												<p className="error-text">Ha ocurrido un error inesperado. Por favor, volvé a intentar más tarde.</p>
											</>
										}
									</div>
								}
							</div>
					}
				</body>
    </div>
  	);
}

export default Transaction;