import React from "react";

import logo from '../../assets/Logo.png';
import './index.css';


function Hello() {

	return (
    <div className="body-background">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
			<body >
				<div className="App-body">
					<div className="title-box">
						<span >Hello</span>
					</div>
				</div>
			</body>
    </div>
  	);
}

export default Hello;