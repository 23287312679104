import axios from 'axios'

const url = process.env.REACT_APP_API_URL + "/1.0";

const TIMEOUT = 15000;

export const get = ({
	params,
	headers,
	apiRoute
}) => {

	const instance = axios.create({
		baseURL: url,
		timeout: TIMEOUT,
	});

	return instance.get(apiRoute, {
		...params && { params },
		...headers && { headers }
	});
};

export const post = ({ body, apiRoute, headers }) => {

	const instance = axios.create({
		baseURL: url,
		timeout: TIMEOUT
	});

	return instance.post(apiRoute, body, {...headers && { headers }});
};

export const put = ({ body, apiRoute, headers }) => {

	const instance = axios.create({
		baseURL: url,
		timeout: TIMEOUT
	});

	return instance.put(apiRoute, body, {...headers && { headers }});
};
