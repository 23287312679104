import React from "react";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import Transaction from './screens/Transaction';
import Hello from './screens/Hello';
import {ROUTES} from './constants/routes';


const App = () => (
	<Router>
		<Routes>
			<Route exact path={ROUTES.TRANSACTION} element={<Transaction/>}/>
			<Route path="*" element={<Hello />} />
		</Routes>
	</Router>
);

export default App;
